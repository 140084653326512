import React, { useState } from 'react'
import { IntegrationProviders } from 'app-constants'
import ViewpointIntegration from 'components/integrations/components/viewpoint'
import BIM360Integration from 'components/integrations/components/bim360'
import ProcoreIntegration from 'components/integrations/components/procore'
import AconexIntegration from 'components/integrations/components/aconex'
import AsiteIntegration from 'components/integrations/components/asite'
import MortaIntegration from 'components/integrations/components/morta'
import ReviztoIntegration from 'components/integrations/components/revizto'
import { useAuth } from 'hooks/auth'
import { useProject } from 'hooks/project'
import { INTEGRATIONS } from 'routes'
import Button from 'components/button'
import { IntegrationButtonProps, IIntegrationSyncResourceTypes } from 'types'

interface IntegrationProps {
  provider?: IntegrationProviders
  type: IIntegrationSyncResourceTypes
  buttonLabel: string
  buttonFunction: (props: IntegrationButtonProps) => void
  buttonLoading: boolean
}

const Integration: React.FC<IntegrationProps> = ({ provider, type, buttonLabel, buttonFunction, buttonLoading }) => {
  const { user } = useAuth()
  const mainProject = useProject()
  // Generic States
  const [project, setProject] = useState<string>('')
  const [folder, setFolder] = useState<string>('')

  // Viewpoint States
  const [enterprise, setEnterprise] = useState<string>('')

  // BIM360 States
  const [hub, setHub] = useState<string>('')
  const [topFolder, setTopFolder] = useState<string>('')
  const [model, setModel] = useState<string>('')
  const [region, setRegion] = useState<string>('')

  // Morta states
  const [projects, setProjects] = useState<string[]>(
    provider === IntegrationProviders.MORTA ? [mainProject.project.publicId] : []
  )

  // Procore States
  const [company, selectCompany] = useState<string>('')

  // Revizto States
  const [license, setLicense] = useState<string>('')

  // Aconex states
  const [docTypes, setDocTypes] = useState<string[]>()

  const integrationProviderName = () => {
    switch (provider) {
      case IntegrationProviders.VIEWPOINT:
        return 'Viewpoint For Projects'
      case IntegrationProviders.AUTODESK_BIM360:
        return 'BIM360'
      case IntegrationProviders.PROCORE:
        return 'Procore'
      case IntegrationProviders.ACONEX:
        return 'Aconex'
      case IntegrationProviders.ASITE:
        return 'Asite'
      case IntegrationProviders.MORTA:
        return 'Morta'
      default:
        return 'Unknown'
    }
  }

  const accountConnected =
    (provider === IntegrationProviders.MORTA && user) ||
    (provider === IntegrationProviders.VIEWPOINT && user && user.viewpointConnected) ||
    (provider === IntegrationProviders.AUTODESK_BIM360 && user && user.autodeskConnected) ||
    (provider === IntegrationProviders.PROCORE && user && user.procoreConnected) ||
    (provider === IntegrationProviders.ACONEX && user && user.aconexConnected) ||
    (provider === IntegrationProviders.ASITE && user && user.asiteConnected) ||
    (provider === IntegrationProviders.REVIZTO && user && user.reviztoConnected)

  const buttonDisabled =
    !provider ||
    (provider === IntegrationProviders.VIEWPOINT && projects.length > 0) ||
    (provider === IntegrationProviders.VIEWPOINT && (enterprise === '' || project === '')) ||
    (provider === IntegrationProviders.AUTODESK_BIM360 &&
      type === 'Documents' &&
      (hub === '' || project === '' || topFolder === '')) ||
    (provider === IntegrationProviders.AUTODESK_BIM360 && type === 'Checklists' && (hub === '' || project === '')) ||
    (provider === IntegrationProviders.AUTODESK_BIM360 && type === 'Issues' && (hub === '' || project === '')) ||
    (provider === IntegrationProviders.AUTODESK_BIM360 &&
      type === 'AEC Data Model' &&
      (hub === '' || project === '' || model === '' || region == '')) ||
    (provider === IntegrationProviders.PROCORE && (company === '' || project === '')) ||
    (provider === IntegrationProviders.ACONEX && project === '') ||
    (provider === IntegrationProviders.ASITE && project === '') ||
    (provider === IntegrationProviders.REVIZTO && (license === '' || project === ''))

  if (!buttonLoading) {
    return (
      <div>
        {provider && provider !== IntegrationProviders.MORTA && (
          <div>
            <div className="font-bold" style={{ marginTop: '50px', marginBottom: '10px' }}>
              Connected Account
            </div>
            {accountConnected ? (
              <div>You have a successfully connected account</div>
            ) : (
              <div>
                You do not have a connected {integrationProviderName()} account. Go to your{' '}
                <a href={INTEGRATIONS}>integration settings</a> to configure.
              </div>
            )}
          </div>
        )}

        {provider === IntegrationProviders.VIEWPOINT && (
          <ViewpointIntegration
            type={type}
            selectedEnterprise={enterprise}
            setSelectedEnterprise={setEnterprise}
            selectedProject={project}
            setSelectedProject={setProject}
            selectedFolder={folder}
            setSelectedFolder={setFolder}
          />
        )}

        {provider === IntegrationProviders.AUTODESK_BIM360 && (
          <BIM360Integration
            type={type}
            selectedHub={hub}
            setSelectedHub={setHub}
            selectedProject={project}
            setSelectedProject={setProject}
            selectedTopFolder={topFolder}
            setSelectedTopFolder={setTopFolder}
            selectedFolder={folder}
            setSelectedFolder={setFolder}
            selectedModel={model}
            setSelectedModel={setModel}
            selectedRegion={region}
            setSelectedRegion={setRegion}
          />
        )}

        {provider === IntegrationProviders.PROCORE && (
          <ProcoreIntegration
            type={type}
            selectedCompany={company}
            setSelectedCompany={selectCompany}
            selectedProject={project}
            setSelectedProject={setProject}
          />
        )}

        {provider === IntegrationProviders.ACONEX && (
          <AconexIntegration
            type={type}
            selectedProject={project}
            setSelectedProject={setProject}
            selectedDocTypes={docTypes}
            setSelectedDocTypes={setDocTypes}
          />
        )}

        {provider === IntegrationProviders.ASITE && (
          <AsiteIntegration type={type} selectedProject={project} setSelectedProject={setProject} />
        )}

        {provider === IntegrationProviders.REVIZTO && (
          <ReviztoIntegration
            type={type}
            selectedLicense={license}
            setSelectedLicense={setLicense}
            selectedProject={project}
            setSelectedProject={setProject}
          />
        )}

        {provider === IntegrationProviders.MORTA && (
          <MortaIntegration type={type} selectedProjects={projects} setSelectedProjects={setProjects} />
        )}

        <div className="flex items-center text-red" style={{ marginTop: '50px' }}>
          <i style={{ marginRight: '5px' }}>* Required Field.</i>
          {provider === IntegrationProviders.VIEWPOINT && enterprise !== '' && project !== '' && folder === '' && (
            <i>You are syncing documents at the project level. This may take a long time.</i>
          )}
          {provider === IntegrationProviders.VIEWPOINT && enterprise !== '' && project !== '' && folder !== '' && (
            <i>You are syncing documents at the folder level.</i>
          )}

          {provider === IntegrationProviders.AUTODESK_BIM360 &&
            hub !== '' &&
            project !== '' &&
            topFolder !== '' &&
            folder === '' && <i>You are syncing documents at the top folder level.</i>}

          {provider === IntegrationProviders.AUTODESK_BIM360 &&
            hub !== '' &&
            project !== '' &&
            topFolder !== '' &&
            folder !== '' && <i>You are syncing documents at the folder level.</i>}

          <Button
            className="ml-auto text-base"
            onClick={() =>
              buttonFunction({
                enterprise,
                hub,
                project,
                topFolder,
                folder,
                company,
                license,
                projects,
                docTypes,
                model,
                region
              })
            }
            isLoading={buttonLoading}
            disabled={buttonDisabled}
            internalType="accept"
          >
            {buttonLabel}
          </Button>
        </div>
      </div>
    )
  } else {
    return (
      <div>Your integration is being built. This may take some time and you will be emailed when it is complete.</div>
    )
  }
}

export default Integration
