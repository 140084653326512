import React, { useState, useEffect, useRef } from 'react'
import Menu, { MenuProps } from 'components/menu'
import { ITableColumn, ITableViewColour } from 'types'
import { IFilterType } from 'components/spreadsheet/types'
import { IColumnTypes, ISelectOption } from 'components/spreadsheet/types'
import ColourItem from 'components/spreadsheet/components/menu/views/colour/ColourItem'
import { getColumnsPerUserRole } from 'components/spreadsheet/helpers/functions'
import { useDataContext } from 'components/spreadsheet/contexts/data'
import Select from 'components/select'
import { cancelTimeout, requestTimeout, TimeoutID } from 'helpers/timer'
export interface ISelectAndKindOption extends ISelectOption {
  kind: IColumnTypes
}
export interface IColourItemProps extends ITableViewColour {
  selectedColumns: ISelectOption[]
  kind: IColumnTypes
  column: ITableColumn
  columnOptions: ISelectAndKindOption[]
  focus: boolean
}

const ColourMenu: React.FC<MenuProps> = ({ id, menuState, setMenuState, width }) => {
  const { spreadsheetData, setSpreadsheetData } = useDataContext()
  const allColumns = getColumnsPerUserRole(spreadsheetData)

  useEffect(() => {
    if (menuState.defaultColour) {
      handleAddColour(menuState.defaultColour.columnId, menuState.defaultColour.filterType)
    }
  }, [])

  const columnOptions: ISelectAndKindOption[] = spreadsheetData.viewDetails.columns
    .filter((column: ITableColumn) => !spreadsheetData.userConfiguration.hiddenColumns.includes(column.publicId))
    .map((column) => ({
      label: column.name,
      value: column.publicId,
      kind: column.kind
    }))

  const areEqualSettings = (currentColour: ITableViewColour, newColour: ITableViewColour) => {
    const currentMultipleValues = currentColour.multipleValues || []
    const newMultipleValues = newColour.multipleValues || []
    return (
      currentColour.value === newColour.value &&
      JSON.stringify(currentMultipleValues) === JSON.stringify(newMultipleValues) &&
      currentColour.filterType === newColour.filterType &&
      currentColour.backgroundColour === newColour.backgroundColour
    )
  }
  const [colourSettings, setColourSettings] = useState<IColourItemProps[]>(() => {
    const groups: IColourItemProps[] = []
    const colours = spreadsheetData.userConfiguration.colourSettings
    const keys = new Set()
    colours.forEach((colour, index) => {
      const column = allColumns.find((c) => c.publicId === colour.columnId)
      if (index === 0) {
        keys.add(
          `${colour.columnId}-${colour.filterType}-${colour.value}-${colour.backgroundColour}-${JSON.stringify(
            colour.multipleValues
          )}`
        )
        groups.push({
          ...colour,
          kind: column!.kind,
          selectedColumns: [{ value: colour.columnId, label: colour.columnName }],
          column: column!,
          columnOptions,
          focus: false
        })
      } else {
        colours.slice(index).forEach((colour) => {
          const key = `${colour.columnId}-${colour.filterType}-${colour.value}-${
            colour.backgroundColour
          }-${JSON.stringify(colour.multipleValues)}`
          if (!keys.has(key)) {
            keys.add(key)
            const findColourSetting = groups.find((group) => areEqualSettings(group, colour))
            if (findColourSetting) {
              findColourSetting.selectedColumns.push({ value: colour.columnId, label: colour.columnName })
            } else {
              groups.push({
                ...colour,
                kind: column!.kind,
                selectedColumns: [{ value: colour.columnId, label: colour.columnName }],
                column: column!,
                columnOptions,
                focus: false
              })
            }
          }
        })
      }
    })
    return groups
  })

  // When there are changes to filters, propagate changes to main state
  const timeoutRef = useRef<TimeoutID | null>(null)
  useEffect(() => {
    const hasChanged = colourSettings !== spreadsheetData.userConfiguration.colourSettings
    if (spreadsheetData.loading || spreadsheetData.streaming || !hasChanged) {
      return
    }
    if (timeoutRef.current) {
      cancelTimeout(timeoutRef.current)
    }

    const keys = new Set()
    let newColourSettings = colourSettings.flatMap((colour) => {
      return colour.selectedColumns.map((col) => {
        const key = `${col.value}-${colour.filterType}-${colour.value}-${colour.backgroundColour}-${JSON.stringify(
          colour.multipleValues
        )}`
        if (keys.has(key)) {
          return undefined
        } else {
          keys.add(key)
          const newColourSetting = {
            backgroundColour: colour.backgroundColour,
            columnId: col.value,
            columnName: col.label,
            filterType: colour.filterType,
            fontColour: colour.fontColour
          }
          if (!!colour.multipleValues && colour.multipleValues.length > 0) {
            return {
              ...newColourSetting,
              multipleValues: colour.multipleValues
            }
          } else {
            return {
              ...newColourSetting,
              value: colour.value || ''
            }
          }
        }
      })
    })
    newColourSettings = newColourSettings.filter((c) => c !== undefined)
    if (
      newColourSettings.length === spreadsheetData.userConfiguration.colourSettings.length &&
      spreadsheetData.userConfiguration.colourSettings
        .map((colour, index) => JSON.stringify(newColourSettings[index]) === JSON.stringify(colour))
        .every((check) => check === true)
    ) {
      return
    }
    timeoutRef.current = requestTimeout(() => {
      setSpreadsheetData({
        type: 'UPDATE_COLOURS',
        colours: newColourSettings as ITableViewColour[]
      })
    }, 1000)
  }, [colourSettings])

  const handleDeleteColour = (index: number) => {
    setColourSettings(colourSettings.filter((_, i) => i !== index))
  }

  const handleColourChange = (newColour: IColourItemProps, prevColour: IColourItemProps) => {
    const newColourSettings: IColourItemProps[] = []
    colourSettings.forEach((colour) => {
      if (areEqualSettings(prevColour, colour)) {
        newColourSettings.push({ ...newColour, focus: prevColour.value !== newColour.value })
      } else {
        newColourSettings.push(colour)
      }
    })
    setColourSettings(newColourSettings)
  }
  const handleOrderChange = (from: number, to: number) => {
    const newColourSettings = colourSettings.map((colour, index) => {
      if (index === from) {
        return { ...colourSettings[to], focus: false }
      } else if (index === to) {
        return { ...colourSettings[from], focus: false }
      } else {
        return { ...colour, focus: false }
      }
    })
    setColourSettings(newColourSettings)
  }
  const handleAddColour = (option: string, filterType?: IFilterType) => {
    const columnName = columnOptions.find((col) => col.value === option)!.label
    const newColour: ITableViewColour = {
      columnId: option,
      columnName: columnName,
      filterType: filterType ? filterType : IFilterType.eq,
      value: '',
      multipleValues: [],
      backgroundColour: '#ffffff',
      fontColour: '#000000'
    }

    let found = false
    const newColourSettings = colourSettings.map((colour) => {
      if (areEqualSettings(colour, newColour)) {
        found = true
        const newColumns = colour.selectedColumns.filter((col) => col.value !== newColour.columnId)
        newColumns.push({ value: newColour.columnId, label: newColour.columnName })
        newColumns.sort((a, b) => a.label.localeCompare(b.label))

        return {
          ...colour,
          selectedColumns: newColumns
        }
      } else {
        return colour
      }
    })
    // if colour setting not found, add new colour setting
    if (!found) {
      const column = allColumns.find((c) => c.publicId === option)!
      newColourSettings.push({
        ...newColour,
        kind: column.kind,
        column: column,
        columnOptions,
        selectedColumns: [{ value: newColour.columnId, label: newColour.columnName }],
        focus: false
      })
    }
    setColourSettings(newColourSettings)
  }

  return (
    <Menu id={id} menuState={menuState} setMenuState={setMenuState} width={width} zIndex={3000}>
      <div style={{ listStyle: 'none', padding: '0px', margin: '10px' }}>
        {!menuState.defaultColour && spreadsheetData.userConfiguration.colourSettings.length === 0 && (
          <div>
            <div className="font-bold truncate" style={{ padding: '3px 10px', marginBottom: '10px' }}>
              You have no active colour settings in this view.
            </div>
            <div className="truncate" style={{ padding: '5px 10px', marginBottom: '20px', marginTop: '20px' }}>
              Colouring allow you to show cells that match your set conditions.
            </div>
          </div>
        )}
        <div style={{ maxHeight: 800, overflowY: 'auto', paddingBottom: 10 }}>
          {colourSettings.length > 0 &&
            colourSettings.map((colourSetting, index) => {
              return (
                <ColourItem
                  key={`colour-item-${index}`}
                  {...colourSetting}
                  onDelete={handleDeleteColour}
                  onChange={handleColourChange}
                  handleOrderChange={handleOrderChange}
                  index={index}
                  style={{}}
                  optionsOpen={false}
                />
              )
            })}
        </div>
        <div className="flex w-full items-center rounded" style={{ padding: '10px', height: '50px' }}>
          <span style={{ width: '200px', marginRight: '10px' }}>Column To Colour:</span>
          <div className="w-full">
            <Select options={columnOptions} onOptionClick={(option) => handleAddColour(option)} />
          </div>
        </div>
      </div>
    </Menu>
  )
}

export default React.memo(ColourMenu)
