import React, { useEffect, useState } from 'react'
import { IntegrationProviders } from 'app-constants'
import useAuth from 'hooks/auth'
import api from 'helpers/api'
import { useApplicationStore } from 'hooks/application'
import { ISyncResource, IIntegrationSyncResourceTypes } from 'types'
import Select from 'components/select'

interface BIM360IntegrationProps {
  type: IIntegrationSyncResourceTypes
  selectedHub: string
  selectedProject: string
  selectedTopFolder: string
  selectedFolder: string
  setSelectedHub: (hub: string) => void
  setSelectedProject: (project: string) => void
  setSelectedTopFolder: (folder: string) => void
  setSelectedFolder: (folder: string) => void
  selectedModel: string
  setSelectedModel: (model: string) => void
  selectedRegion: string
  setSelectedRegion: (region: string) => void
}

const BIM360: React.FC<BIM360IntegrationProps> = ({
  type,
  selectedHub,
  selectedProject,
  selectedTopFolder,
  selectedFolder,
  setSelectedHub,
  setSelectedProject,
  setSelectedTopFolder,
  setSelectedFolder,
  selectedModel,
  setSelectedModel,
  selectedRegion,
  setSelectedRegion
}) => {
  const { user } = useAuth()
  const { displayErrorMessage } = useApplicationStore()

  const [hubs, setHubs] = useState<ISyncResource[]>()
  const [loadingHubs, setLoadingHubs] = useState<boolean>(false)

  const [projects, setProjects] = useState<ISyncResource[]>()
  const [loadingProjects, setLoadingProjects] = useState<boolean>(false)

  const [topFolders, setTopFolders] = useState<ISyncResource[]>()
  const [loadingTopFolders, setLoadingTopFolders] = useState<boolean>(false)

  const [folders, setFolders] = useState<ISyncResource[][]>([])
  const [selectedFolders, setSelectedFolders] = useState<string[]>([])
  const [loadingFolders, setLoadingFolders] = useState<boolean>(false)

  const [models, setModels] = useState<ISyncResource[]>()
  const [loadingModels, setLoadingModels] = useState<boolean>(false)

  const resetFolders = () => {
    setSelectedFolder('')
    setFolders([])
    setSelectedFolders([])
  }

  useEffect(() => {
    if (user && user.autodeskConnected) {
      setLoadingHubs(true)
      api
        .integrationPassthrough({
          method: 'GET',
          path: 'https://developer.api.autodesk.com/project/v1/hubs',
          sourceSystem: IntegrationProviders.AUTODESK_BIM360,
          headers: {
            Authorization: 'Bearer $token$'
          }
        })
        .then((response) => {
          let hubs: ISyncResource[] = []
          if (response.data.status === '200') {
            hubs = response.data['body']['data'].map((hub: any) => {
              return {
                id: hub['id'],
                name: hub['attributes']['name'],
                region: hub['attributes']['region']
              }
            })
          }

          setHubs(hubs)
          setLoadingHubs(false)
        })
        .catch((error) => {
          displayErrorMessage(error)
          setLoadingHubs(false)
        })
    }
  }, [])

  useEffect(() => {
    if (user && user.autodeskConnected && selectedHub && selectedHub !== '') {
      setLoadingProjects(true)

      if (type === 'AEC Data Model') {
        // First get the hubs
        api
          .integrationPassthrough({
            method: 'POST',
            path: 'https://developer.api.autodesk.com/aec/graphql',
            sourceSystem: IntegrationProviders.AUTODESK_BIM360,
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer $token$',
              Region: selectedRegion
            },
            data: {
              query: `query GetHubs {
              hubs {
                pagination {
                  cursor
                }
                results{
                  name
                  id
                  alternativeIdentifiers {
                    dataManagementAPIHubId
                  }
                }
              }
            }`
            }
          })
          .then((response) => {
            if (response.data.status === '200') {
              const foundHub = response.data['body']['data']['hubs']['results'].find(
                (hub: any) => hub['alternativeIdentifiers']['dataManagementAPIHubId'] === selectedHub
              )
              api
                .integrationPassthrough({
                  method: 'POST',
                  path: 'https://developer.api.autodesk.com/aec/graphql',
                  sourceSystem: IntegrationProviders.AUTODESK_BIM360,
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer $token$',
                    Region: selectedRegion
                  },
                  data: {
                    query: `
                    query GetProjects($hubId: ID!) {
                      projects(hubId: $hubId) {
                        pagination {
                          cursor
                        }
                        results{
                          name
                          id
                        }
                      }
                    }
                    `,
                    variables: {
                      hubId: foundHub.id
                    }
                  }
                })
                .then((response) => {
                  let projects: ISyncResource[] = []
                  if (response.data.status === '200') {
                    projects = response.data['body']['data']['projects']['results'].map((project: any) => {
                      return {
                        id: project['id'],
                        name: project['name']
                      }
                    })
                  }
                  setProjects(projects)
                  setLoadingProjects(false)
                })
                .catch((error) => {
                  displayErrorMessage(error)
                  setLoadingProjects(false)
                })
            }
          })
      } else {
        api
          .integrationPassthrough({
            method: 'GET',
            path: `https://developer.api.autodesk.com/project/v1/hubs/${selectedHub}/projects`,
            sourceSystem: IntegrationProviders.AUTODESK_BIM360,
            headers: {
              Authorization: 'Bearer $token$'
            }
          })
          .then((response) => {
            let projects: ISyncResource[] = []
            if (response.data.status === '200') {
              projects = response.data['body']['data'].map((project: any) => {
                return {
                  id: project['id'],
                  name: project['attributes']['name']
                }
              })
            }
            setProjects(projects)
            setLoadingProjects(false)
          })
          .catch((error) => {
            displayErrorMessage(error)
            setLoadingProjects(false)
          })
      }
    }
  }, [selectedHub])

  useEffect(() => {
    if (user && user.autodeskConnected && selectedProject && selectedProject !== '' && type === 'Documents') {
      setLoadingTopFolders(true)
      api
        .integrationPassthrough({
          method: 'GET',
          path: `https://developer.api.autodesk.com/project/v1/hubs/${selectedHub}/projects/${selectedProject}/topFolders`,
          sourceSystem: IntegrationProviders.AUTODESK_BIM360,
          headers: {
            Authorization: 'Bearer $token$'
          }
        })
        .then((response) => {
          let topFolders: ISyncResource[] = []
          if (response.data.status === '200') {
            topFolders = response.data['body']['data'].map((topFolder: any) => {
              return {
                id: topFolder['id'],
                name: topFolder['attributes']['name']
              }
            })
          }
          setTopFolders(topFolders)
          setLoadingTopFolders(false)
        })
        .catch((error) => {
          displayErrorMessage(error)
          setLoadingTopFolders(false)
        })
    }
  }, [selectedProject])

  useEffect(() => {
    if (user && user.autodeskConnected && selectedTopFolder && selectedTopFolder !== '') {
      setLoadingFolders(true)
      const foldersSet = selectedFolder && selectedFolder !== ''
      const folderId = foldersSet ? selectedFolder : selectedTopFolder

      api
        .integrationPassthrough({
          method: 'GET',
          path: `https://developer.api.autodesk.com/data/v1/projects/${selectedProject}/folders/${folderId}/contents`,
          sourceSystem: IntegrationProviders.AUTODESK_BIM360,
          headers: {
            Authorization: 'Bearer $token$'
          }
        })
        .then((response) => {
          let folders: ISyncResource[] = []
          if (response.data.status === '200') {
            folders = response.data['body']['data']
              .filter((folder: any) => folder.type === 'folders')
              .map((folder: any) => {
                return {
                  id: folder['id'],
                  name: folder['attributes']['name']
                }
              })
          }
          if (foldersSet) {
            setFolders((existingFolders) => [...existingFolders, folders])
          } else {
            setFolders([folders])
          }

          setLoadingFolders(false)
        })
        .catch((error) => {
          displayErrorMessage(error)
          setLoadingFolders(false)
        })
    }
  }, [selectedTopFolder, selectedFolder])

  useEffect(() => {
    if (user && user.autodeskConnected && selectedProject && selectedProject !== '' && type === 'AEC Data Model') {
      setLoadingModels(true)

      api
        .integrationPassthrough({
          method: 'POST',
          path: 'https://developer.api.autodesk.com/aec/graphql',
          sourceSystem: IntegrationProviders.AUTODESK_BIM360,
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer $token$',
            Region: selectedRegion
          },
          data: {
            query: `query GetElementGroupsByProject($projectId: ID!) {
            elementGroupsByProject(projectId: $projectId) {
              pagination {
                cursor
              }
              results{
                name
                id
              }
            }}`,
            variables: {
              projectId: selectedProject
            }
          }
        })
        .then((response) => {
          let models: ISyncResource[] = []
          if (response.data.status === '200') {
            models = response.data['body']['data']['elementGroupsByProject']['results'].map((model: any) => {
              return {
                id: model['id'],
                name: model['name']
              }
            })
          }
          setModels(models)
          setLoadingModels(false)
        })
        .catch((error) => {
          displayErrorMessage(error)
          setLoadingModels(false)
        })
    }
  }, [selectedProject])

  return (
    <div>
      {user && user.autodeskConnected && (
        <div style={{ marginTop: '30px' }}>
          <div className="font-bold flex" style={{ marginBottom: '10px' }}>
            Select Hub<span className="text-red">*</span>
          </div>

          <Select
            options={
              hubs
                ? hubs
                    .sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0))
                    .map((hub) => {
                      return {
                        label: hub.name,
                        value: hub.id
                      }
                    })
                : []
            }
            onOptionClick={(option) => {
              resetFolders()
              setSelectedHub(option)

              if (hubs && type === 'AEC Data Model') {
                // Find the option in the hubs array
                const selectedHub = hubs.find((hub) => hub.id === option)
                if (selectedHub && selectedHub.region) {
                  setSelectedRegion(selectedHub.region)
                }
              }

              setSelectedProject('')
              setProjects(undefined)
              setSelectedTopFolder('')
              setTopFolders(undefined)
            }}
            optionsSelected={[selectedHub]}
            loading={loadingHubs}
          />
        </div>
      )}

      {user && user.autodeskConnected && selectedHub !== '' && (
        <div style={{ marginTop: '30px' }}>
          <div className="font-bold flex" style={{ marginBottom: '10px' }}>
            Select Project<span className="text-red">*</span>
          </div>
          <Select
            options={
              projects
                ? projects
                    .sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0))
                    .map((project) => {
                      return {
                        label: project.name,
                        value: project.id
                      }
                    })
                : []
            }
            onOptionClick={(option) => {
              resetFolders()
              setSelectedProject(option)
              setSelectedTopFolder('')
              setTopFolders(undefined)
            }}
            optionsSelected={[selectedProject]}
            loading={loadingProjects}
          />
        </div>
      )}

      {user && user.autodeskConnected && selectedProject !== '' && type === 'Documents' && (
        <div style={{ marginTop: '30px' }}>
          <div className="font-bold flex" style={{ marginBottom: '10px' }}>
            Select Top Level Document Folder<span className="text-red">*</span>
          </div>
          <Select
            options={
              topFolders
                ? topFolders
                    .sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0))
                    .map((topFolder) => {
                      return {
                        label: topFolder.name,
                        value: topFolder.id
                      }
                    })
                : []
            }
            onOptionClick={(option) => {
              resetFolders()
              setSelectedTopFolder(option)
            }}
            optionsSelected={[selectedTopFolder]}
            loading={loadingTopFolders}
          />
        </div>
      )}

      {folders.map((folder, index) => {
        return (
          <div key={index} style={{ marginTop: '30px' }}>
            <div className="font-bold flex" style={{ marginBottom: '10px' }}>
              Select Specific Document Folder (Level {index + 1})
            </div>
            <Select
              options={folder
                .sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0))
                .map((folder) => {
                  return {
                    label: folder.name,
                    value: folder.id
                  }
                })}
              onOptionClick={(option) => {
                setSelectedFolder(option)

                const newFolders = [...folders]
                newFolders[index] = folder
                newFolders.splice(index + 1, newFolders.length - index - 1)
                setFolders(newFolders)

                const newSelectedFolders = [...selectedFolders]
                newSelectedFolders[index] = option
                newSelectedFolders.splice(index + 1, newSelectedFolders.length - index - 1)
                setSelectedFolders(newSelectedFolders)
              }}
              optionsSelected={[selectedFolders[index]]}
              loading={index === folders.length - 1 ? loadingFolders : false}
            />
          </div>
        )
      })}

      {user && user.autodeskConnected && selectedProject !== '' && type === 'AEC Data Model' && (
        <div style={{ marginTop: '30px' }}>
          <div className="font-bold flex" style={{ marginBottom: '10px' }}>
            Select Model<span className="text-red">*</span>
          </div>
          <Select
            options={
              models
                ? models
                    .sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0))
                    .map((model) => {
                      return {
                        label: model.name,
                        value: model.id
                      }
                    })
                : []
            }
            onOptionClick={(option) => {
              setSelectedModel(option)
            }}
            optionsSelected={[selectedModel]}
            loading={loadingModels}
          />
        </div>
      )}
    </div>
  )
}

export default BIM360
